/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  TwitterShareButton, FacebookShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon,
} from 'react-share';
import Seo from '../components/Seo';
import EpisodesLayout from '../components/EpisodesLayout';

import PlayIcon from '../icons/Play';
import DashIcon from '../icons/Dash';

export const query = graphql`
  query getcontentfulUnderdogListPost($slug: String!){
    contentfulUnderdogList(slug: {eq: $slug}) {
      id
      tags
      slug
      videoUrl
      releaseDate
      interviewUrl
      galleryTitle
      featuredName
      featuredPosition
      interviewCaption
      featuredDescription {
        featuredDescription
      }
      featuredPicture {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      gallery {
        id
        title
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
const UnderdogPage = ({ data }) => {
  const { contentfulUnderdogList: videoBox } = data || {};
  const imagePosts = data.contentfulUnderdogList.gallery.reverse();

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-background">
      <div className="mx-auto max-w-[85.375rem] bg-background shadow-xl shadow-[#000000ad]">
        <EpisodesLayout>
          <Seo title={videoBox?.featuredName} />
          <section className="">
            <div className="h-auto w-full relative flex items-center justify-center">
              <div className="md:h-auto object-cover lg:h-[600px] h-auto w-full">
                <GatsbyImage image={videoBox.heroImage.gatsbyImageData} alt={videoBox.featuredName} className="block mx-auto w-full h-full z-0 group-hover:opacity-50 group-hover:shadow-lg relative" />
              </div>
              <div className="absolute -translate-x-1/2 -translate-y-1/2 mx-auto">
                <button
                  className="
                  hover:border-white
                  hover:opacity-90
                  hover:shadow-md
                  hover:scale-125
                  flex
                  items-center
                  justify-center
                  p-0 w-16 h-16
                  bg-primary
                  rounded-full
                  active:shadow-lg
                  mouse
                  shadow
                  focus:outline-none absolute mx-auto top-[60%] left-[50%] cursor-pointer hover-text-text text-white"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  <PlayIcon />
                </button>
              </div>
              {showModal ? (
                <div
                  className="opacity-100 marker:justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                  <div className="relative flex flex-col w-full h-full bg-outlined">
                    <div className="flex items-center justify-end">
                      <button
                        className="cursor-pointer background-transparent px-6 py-2 text-sm outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50">
                          <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                          </svg>
                        </div>
                      </button>
                    </div>
                    <div className="py-[2.475rem] px-[1rem] lg:px-[3.125rem] h-full">
                      <iframe className="w-full h-full" src={videoBox?.videoUrl} title={videoBox?.featuredName} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      {' '}

                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="py-[40px] realtive lg:px-[3.125rem] px-[1.125rem] bg-outlined shadow-lg fd-cl">
              <div className="md:mx-auto">
                <div className="flex flex-wrap">
                  <div className="md:w-[40%] w-full md:pr-[2.475rem] relative">
                    <div className="w-full mb-[12px] md:mb-0">
                      <GatsbyImage
                        image={videoBox.featuredPicture.gatsbyImageData}
                        alt={videoBox.featuredName}
                        className="md:max-w-none w-full h-full object-contain"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </div>
                  </div>
                  <div className="md:w-[60%] w-full">
                    <h1 className="block text-headerH1 text-white font-bold tracking-wide uppercase">{videoBox.featuredName}</h1>
                    <h2 className="text-text mt-[6px]">{videoBox.featuredPosition}</h2>
                    <div className="flex items-center justify-between text-muted2">
                      <div className="flex space-x-4 md:space-x-8 my-[12px]">
                        <div className="flex cursor-pointer items-center transition">
                          <ul className="flex text-text">
                            <li className="mr-[2px] hover:text-slate-600">{videoBox.tags}</li>
                          </ul>
                        </div>
                        <div className="flex items-center">
                          -
                        </div>
                        <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                          <span>{videoBox.releaseDate}</span>
                        </div>
                      </div>
                    </div>
                    <p className="text-text text-headerP contents">
                      {videoBox.featuredDescription.featuredDescription}
                      <span className="text-primary ml-[2px] cursor-pointer">
                        <button onClick={() => setShowModal(true)} type="button">
                          {videoBox.interviewCaption}
                        </button>
                      </span>
                    </p>
                    {showModal ? (
                      <div
                        className="opacity-100 marker:justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                      >
                        <div className="relative flex flex-col w-full h-full bg-outlined">
                          <div className="flex items-center justify-end">
                            <button
                              className="cursor-pointer background-transparent px-6 py-2 text-sm outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setShowModal(false)}
                            >
                              <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50">
                                <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                </svg>
                              </div>
                            </button>
                          </div>
                          <div className="py-[2.475rem] px-[1rem] lg:px-[3.125rem] h-full">
                            <iframe className="w-full h-full" src={videoBox?.interviewUrl} title={videoBox?.featuredName} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                            {' '}

                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="relative">
                      <h3 className="text-muted2 mx-0 my-[0.5rem] font-normal text-[14px]">Share this post:</h3>
                      <ul className="list-none pl-0 mt-[0.25rem] mx-[-0.25rem]">
                        <li className="flex flex-wrap px-0 lg:px-[0.25rem] relative space-x-2">
                          <span className="p-1 ">
                            <FacebookShareButton url={`https://hightechtvshow.com/'${videoBox.slug}`}>
                              <FacebookIcon size={35} round />
                            </FacebookShareButton>
                          </span>

                          <span className="p-1 ">
                            <TwitterShareButton url={`https://hightechtvshow.com/'${videoBox.slug}`}>
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </span>
                          <span className="p-1">
                            <WhatsappShareButton url={`https://hightechtvshow.com/'${videoBox.slug}`}>
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {imagePosts ? (
                    <div className="pt-[80px]">
                      <div className="mb-[20px]">
                        <h1 className="text-heading font-normal relative md:text-title1 mb-[2px]">
                          {videoBox?.galleryTitle}
                        </h1>
                        <DashIcon />
                      </div>
                      <div className="flex flex-wrap">
                        {imagePosts?.map(({
                          gatsbyImageData,
                        }, index) => (
                          <div key={index} className="hover:z-30 z-20 mb-8 w-full md:w-1/2 select-none lg:w-1/3 pr-[0.5rem] group duration-200 delay-75">
                            <div className="relative z-30 transition transform duration-500 md:group-hover:scale-110 lg:group-hover:scale-[1.07]  group-hover:scale-105">
                              <div className="h-[250px] lg:hover:h-full hover:h-0 lg:hover:w-full transition transform duration-150 ease-in after:group-hover:border-primary after:group-hover:bottom-[-3px] after:h-0 after:w-full after:absolute after:rounder-full after:group-hover:border-b-[0.25rem] after:left-0 after:z-10">
                                <GatsbyImage
                                  image={gatsbyImageData}
                                  className="img-responsive relative h-full w-full overflow-hidden"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <article className="max-w-screen-md mx-auto ">
                    <div className="flex justify-center mt-7 mb-7">
                      <div>
                        <a href="/whatson" alt="all whats on post" className="cursor-pointer px-5 py-2 text-sm text-primary rounded-full hover:text-white">
                          ← View all posts
                        </a>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <hr className="border-0 w-[90%] h-[1px] opacity-50  bg-gradient-to-r from-transparent via-border-color to-transparent" />
          </section>
        </EpisodesLayout>
      </div>
    </div>
  );
};
export default UnderdogPage;
